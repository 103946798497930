<template>
  <div>
    <b-modal
      id="modal-input"
      size="lg"
      centered
      title="Tambah Data Potongan Asuransi"
      header-bg-variant="primary"
      header-text-variant="light"
    >
      <b-form>
        <b-form-group label="Karyawan" label-cols-md="3">
          <multiselect
            :state="checkIfValid('dataKaryawanId')"
            v-model="$v.data.dataKaryawanId.$model"
            :disabled="false"
            :options="karyawan"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="namaKaryawan"
            track-by="dataKaryawanId"
            @input="itikiwir(data.dataKaryawanId)"
            placeholder="-- Pilih Karyawan --"
          ></multiselect>
        </b-form-group>

        <b-form-group label="Asuransi" label-cols-md="3">
          <multiselect
            :state="checkIfValid('masterAsuransiId')"
            v-model="$v.data.masterAsuransiId.$model"
            :disabled="false"
            :options="asuransi"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="namaAsuransi"
            track-by="id"
            @input="itikiwir2(data.masterAsuransiId)"
            placeholder="-- Pilih Asuransi --"
          ></multiselect>
        </b-form-group>

        <b-form-group label="Potongan" label-cols-md="3">
          <b-form-input
            type="number"
            :state="checkIfValid('potonganAsuransi')"
            v-model="$v.data.potonganAsuransi.$model"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Nomor" label-cols-md="3">
          <b-form-input
            type="number"
            :state="checkIfValid('nomorAsuransi')"
            v-model="$v.data.nomorAsuransi.$model"
          ></b-form-input>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-input')">
          Batal
        </b-button>
        <b-button
          variant="primary"
          :disabled="busy || !isValid"
          @click="simpan()"
        >
          {{ button }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import _ from "lodash";
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
export default {
  name: "modalInput",
  props: ["asuransi", "karyawan"],
  components: {
    Multiselect,
  },
  data() {
    return {
      data: {
        dataKaryawanId: "",
        masterAsuransiId: "",
        potonganAsuransi: "",
        nomorAsuransi: "",
      },
      busy: false,
      button: "Simpan",
    };
  },
  computed: {
    formString() {
      return JSON.stringify(this.data, null, 4);
    },
    isValid() {
      return !this.$v.data.$invalid;
    },
    isDirty() {
      return this.$v.data.$anyDirty;
    },
  },
  mixins: [validationMixin],
  validations: {
    data: {
      dataKaryawanId: {
        required,
      },
      masterAsuransiId: {
        required,
      },
      potonganAsuransi: {
        required,
      },
      nomorAsuransi: {
        required,
      },
    },
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.data[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    itikiwir(x) {
    },
    itikiwir2(x) {
    },
    simpan() {
      let vm = this;
      vm.busy = true;
      vm.button = "Mohon Tunggu";
      vm.data.masterAsuransiId = vm.data.masterAsuransiId.id;
      vm.data.dataKaryawanId = vm.data.dataKaryawanId.dataKaryawanId;
      axios
        .post(ipBackend + "poolPotonganAsuransi/register", vm.data)
        .then((res) => {
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "success",
              msg: "BERHASIL MENDAFTARKAN DATA ASURANSI",
              showing: true,
            });
            vm.data.masterAsuransiId = "";
            vm.data.dataKaryawanId = "";
            vm.data.nomorAsuransi = "";
            vm.data.potonganAsuransi = "";
            vm.$bvModal.hide("modal-input");
            vm.$v.$reset()
          } else {
            vm.button = "Simpan";
            vm.busy = false;

            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: _.toUpper(res.data.message),
              showing: true,
            });
            vm.data.masterAsuransiId = "";
            vm.data.dataKaryawanId = "";
            vm.data.nomorAsuransi = "";
            vm.data.potonganAsuransi = "";
            vm.$bvModal.hide("modal-input");
          }
        })
        .catch((err) => {
          vm.button = "Simpan";
          vm.busy = false;
          vm.$emit("alertFromChild", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
          vm.data.masterAsuransiId = "";
          vm.data.dataKaryawanId = "";
          vm.data.nomorAsuransi = "";
          vm.data.potonganAsuransi = "";
          vm.$bvModal.hide("modal-input");
        });
    },
  },
};
</script>
